import moment from 'moment'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'

import 'moment/locale/fr'
moment.locale('fr')
moment.relativeTimeRounding(Math.floor)
moment.relativeTimeThreshold('s', 59)
moment.relativeTimeThreshold('m', 59)

const tz = 'Europe/Zurich'

export const useTimeDifference = (item: any) => {
  const time = item?.rt || item?.th
  moment.updateLocale('fr', {
    timezone: tz,
    relativeTime: {
      s: '<1',
      past: 'Il y a %s',
      future: '%s',
      m: "%d'",
      mm: "%d'",
      h: '>%dh',
      hh: '>%dh',
    },
  })
  return moment(time).fromNow()
}

export const useMoreThanAHour = (departure: any) => {
  const time = departure?.rt || departure?.th
  return !!dayjs().diff(dayjs(time), 'hours')
}

export const useLessThanOneMinute = (departure: any) => {
  const time = departure?.rt || departure?.th

  return !dayjs().diff(dayjs(time), 'minutes')
}

export const useLessThanHalfAMinute = (departure: any) => {
  const time = departure?.rt || departure?.th
  return dayjs().diff(dayjs(time), 'seconds') > -30
}

export const useOutdated = (departure: any) => {
  const time = departure?.rt || departure?.th
  const now = dayjs();
  const bus = dayjs(time);
  return now.diff(bus, 'seconds') >= 0
}

export const useNow = () => {
  dayjs.locale('fr')
  return dayjs().format('dddd D MMMM HH:mm:ss')
}
